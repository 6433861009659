@media (min-width: 576px) {
  .content-wrapper {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .content-wrapper {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .content-wrapper {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .content-wrapper {
    max-width: 1140px;
  }
}

ul {
  list-style: none;
}

ol {
  padding-left: 20px;
}

/* Slider Styles */
.p-slider.p-slider-horizontal {
  margin-top: 10px;
  max-width: 300px;
}

.p-slider-horizontal .p-slider-range {
  border-radius: 10px;
}

.p-slider .p-slider-handle {
  width: 22px;
}

.cart-item-image {
  max-width: 114px;
  aspect-ratio: 3/4;
}

.cart-label {
  display: block;
  color: var(--primary);
  font-weight: 500;
}

.cart-button {
  float: right;
}

@media (min-width: 768px) {
  .cart-left {
    padding-right: 30px;
  }
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.p-button-primary {
  background-color: #4f6461;
  border-color: #4f6461;
}

.p-button-text .p-button-help .btn-view-desc {
  color: #656565;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.item-image {
  width: 430px;
  flex-shrink: 0;
}

.page-header {
  color: #b68d40;
  font-size: 61px;
  font-family: "Cormorant Garamond";
  font-style: normal;
}

.design-types {
  display: flex;
  flex-wrap: wrap;
}

.img-item {
  width: 100px;
  height: 120px;
  flex-shrink: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #4f6461;
}

.el-switch {
  display: flex;
  flex-direction: row;
}

.item-details {
  margin-top: 150px;
}

.p-button-help {
  font-size: 0.75rem;
  color: #656565;
  background-color: #fff;
}

.selection-button {
  background-color: #eaecec;
}

.item-image-2 {
  width: 400px;
  height: 420px;
  border-radius: 8px;
  background: #fff;
  padding: 25.413px 20px 10.587px 22px;
}

.measurement-points {
  list-style-type: lower-alpha;
}

.measurement-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.measurment-list-order {
  color: #4f6461;
  font-family: "Cormorant Garamond";
  font-size: 30px;
  font-weight: 700;
}

.img-measure {
  width: 368px;
  height: 350px;
}

.height-details {
  margin-top: 20px;
}

.pleats-design {
  margin-top: 20px;
}

.pole-design {
  margin-top: 20px;
  margin-left: 50px;
}

.height-measurement-div {
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  border-radius: 8px;
  border: 1px dashed #656565;
  width: 350px;
  height: 100px;
}

.customize-design-h1 {
  font-size: 61px;
  margin-top: 100px;
  margin: 20px 0;
  margin-top: 40px;
}

.recommended-div {
  gap: 20px;
  display: flex;
  flex-direction: row;
}

.recommended-div-2 {
  align-content: center;
  color: #000;
  font-family: DM Sans;
  font-size: 12px;
}

@media (max-width: 768px) {
  .recommended-div {
    flex-direction: column;
    gap: 5px;
  }
}

.pleats-set-1 {
  width: 150px;
  height: 170px;
}

.pleats-set-2 {
  width: 200px;
  height: 210px;
}

.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text,
.p-fileupload-choose.p-button-secondary.p-button-text {
  background-color: transparent !important;
  color: #000;
  border-color: transparent;
}

.p-button.p-fileupload-choose {
  background-color: #4f6461;
}

.login-submit-btn {
  margin-bottom: 100px;
}

.item-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 105%;
  height: 100%;
  border: 0.1px solid #4f6461;
  border-radius: 5px;
  padding: 10px;
  transform: translateY(-6px);
  transition: transform 0.2s ease, border-color 0.2s ease;
  background-color: #fff;
  box-sizing: border-box;
}

.item-frame img {
  width: 70%;
  height: 70%;
  object-fit: fill;
  margin-bottom: 3px;
  border: none;
  outline: none;
}

.item-name {
  font-size: 14px;
  color: #333;
  text-align: center;
  white-space: wrap;
  overflow: visible;
  text-overflow: ellipsis;
}

.active-frame {
  transform: scale(1.05);
  border: 2px solid #656565;
  outline: 2px solid #656565;
}

.p-button.p-button-text {
  color: #4f6461;
}

.p-button.p-button-white {
  color: #fff;
}
