.crafted {
  text-align: center;
  line-height: 45px;
  font-size: 8rem;
}

.luxury {
  margin-left: -100px;
  font-size: 8rem;
}

.luxury {
  margin-top: 100px;
}

.info-wrapper {
  background-image: url("../img/home.img/background.png");
  background-size: cover;
  background-position: center;
}

.recent-vlogs-text {
  font-size: 2.5rem;
  margin-top: -30px;
  margin-left: -115px;
}

.vlog-iamge {
  width: 100%;
}

.vlog-measure {
  margin-top: -6rem;
}

.vlog-quality {
  margin-top: 11rem;
}

.p-carousel-indicator.p-highlight button {
  background: var(--secondary-color);
}

.latest-image {
  margin-left: 7%;
  max-width: 85%;
  border-radius: 8px;
  aspect-ratio: 3 / 4;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.latest-image:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.latest-info {
  text-decoration: none;
}

.innovative-image {
  aspect-ratio: 1;
  width: 100%;
}

.home-image-1 {
  width: 500px;
  height: 400px;
}

.home-image-2 {
  position: absolute;
  top: 10px;
  right: 195px;
  height: 180px;
}

.home-image-3 {
  height: 350px;
  position: absolute;
  top: 160px;
  right: 0;
}

.home-frame-wraper {
  padding: 10px;
  padding-top: 20px;
  transition: box-shadow 0.3s ease 0s;
  flex-direction: column;
}

.home-frames {
  width: 40px;
  height: 40px;
}

.vertical-sentence {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
  text-align: start;
  font-family: "Cormorant Garamond";
  color: #fff;
}

.explore-button {
  border-radius: 4px;
  border: 2px solid #4f6461;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: #4f6461;
  margin-left: 780px;
  margin-top: 100px;
}

.landing-wrapper {
  position: relative;
}

.latest-link {
  text-decoration: none;
}

.whatsapp {
  position: fixed;
  z-index: 20;
  right: 20px;
  bottom: 20px;
  color: white;
  background-color: #25d366;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.circle-image {
  position: absolute;
  margin-top: 150px;
  margin-left: -80px;
}

@media (max-width: 768px) {
  .crafted {
    text-align: center;
    line-height: 45px;
    font-size: 4.5rem;
  }

  .luxury {
    margin-left: -100px;
    font-size: 4.5rem;
  }

  .luxury {
    margin-top: 65px;
  }

  .contact-title1 {
    font-size: 80px;
  }

  .contact-title2 {
    font-size: 80px;
    margin-left: 250px;
  }

  .contact-title3 {
    margin-top: 10px;
    font-size: 40px;
    margin-left: 220px;
  }

  .contact-us-img {
    height: 400px;
  }

  .circle-image {
    margin-top: 30px;
    margin-left: -35px;
    width: 80px;
  }
}

.contact-us-img {
  height: 600px;
}

.hide-on-desktop {
  display: none;
}

@media (max-width: 768px) {
  .desktop-specs,
  .hide-on-mobile {
    display: none !important;
  }

  .hide-on-desktop {
    display: block !important;
  }

  .home-frames {
    width: 30px;
    height: 30px;
  }

  .home-image-1 {
    width: 100%;
    height: 300px;
  }

  .home-image-2 {
    display: none;
  }

  .home-image-3 {
    height: 200px;
    width: 150px;
    margin-top: 20px;
  }

  .curtains-header {
    font-size: 6rem !important;
    position: absolute;
    margin: 0 !important;
    top: -50px;
  }

  .defines-header {
    width: auto !important;
    position: absolute;
    margin: 0 !important;
    top: 15px;
    right: 15px;
  }

  .defines-text {
    font-size: 4rem !important;
  }

  .landing-wrapper {
    height: 360px;
    position: relative !important;
  }

  .explore-button {
    position: absolute;
    margin-top: 230px;
    margin-left: -320px;
  }

  .explore-button {
    border: 2px solid #e3f1ef;
    color: #e3f1ef;
  }

  .latest-mobile {
    margin-left: -70px;
    margin-bottom: 20px;
  }

  .recent-vlogs-text {
    margin-bottom: 100px;
  }

  .button-vlog-1 {
    margin-top: -15px;
  }

  .button-vlog-2 {
    margin-bottom: -15px;
  }

  .vlog-website {
    margin-top: 20px;
  }

  .vlog-quality {
    margin-top: 2rem;
  }

  .button-vlog-3 {
    margin-top: -15px;
  }

  .home-frame-wraper {
    padding: 10px;
  }

  .info-right {
    display: none;
  }

  .crafted {
    text-align: center;
    line-height: 5px;
    font-size: 3.5rem;
  }

  .luxury {
    margin-left: -100px;
    font-size: 3.5rem;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .latest-link {
    width: 50%;
  }

  .whatsapp {
    bottom: 10px;
    right: 10px;
    padding: 10px;
  }
}

.frame-paras {
  color: #b68d40;
  font-family: "DM Sans";
}

.btn-text-white {
  background-color: transparent;
  color: #fff;
  border: none;
}

.whatsapp:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
  transform: translateY(-5px);
}

.p-sidebar-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.testimonial-container {
  background-color: #f9fafb;
}
