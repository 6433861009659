.text-elegance {
  margin-left: 350px;
}

.page-image-header {
  height: 440px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-pages .p-paginator-page {
  margin-top: 1.5rem;
}

.gallery-p {
  max-width: 700px;
}

@media (max-width: 768px) {
  .text-elegance {
    margin-left: 150px;
  }

  .page-image-header {
    height: 340px;
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last,
  .p-paginator .p-paginator-pages .p-paginator-page {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
  }
}
