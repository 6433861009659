:root {
  --primary-color: #4f6461;
  --secondary-color: #b68d40;
  --secondary-color-400: #c19e5d;
  --info-color: #656565;
  --primary-background: rgba(79, 100, 97, 0.12);
  --frosted-background: rgba(79, 100, 97, 0.7);
  --background: #fbfbfb;
  --dark-bg: #252525;
  --text-color: #4b4b4b;
  --text-info: #6a6a6a;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: "DM Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
  box-shadow: inset 0 0 0 2px #f1f1f1;
}

.text-color {
  color: var(--text-color) !important;
}

.text-dark {
  color: var(--dark-bg);
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.text-info {
  color: var(--text-info) !important;
}

.text-10xl {
  font-size: 10rem;
}

.p-dataview .p-dataview-content {
  background-color: transparent;
}

.p-component {
  font-family: "DM Sans", sans-serif !important;
}

.p-button.p-button-secondary:not(:disabled) {
  background: var(--secondary-color);
}
.p-button.p-button-secondary:not(:disabled):active,
.p-button.p-button-secondary:not(:disabled):hover {
  background: var(--secondary-color-400);
}

.p-paginator,
.p-dataview,
.p-dataview-header {
  background-color: transparent;
  border-bottom: none;
  padding: 0px;
}

.p-highlight {
  background: var(--primary-background);
  color: var(--primary-color);
}

.p-menubar-root-list {
  gap: 50px;
  justify-content: center;
}

.p-menubar-end {
  margin-left: 0;
}

.p-menuitem {
  color: #fff;
}

.p-dataview-content {
  padding: 0;
}

.p-inputtext:enabled:focus,
.p-focus {
  border-color: var(--primary-color);
  box-shadow: inset 0 0 0 1px var(--primary-color),
    inset 0 0 0 1px var(--primary-color), inset 0 0 0 1px var(--primary-color),
    inset 0 0 0 1px var(--primary-color);
}

hr {
  width: 100%;
}

.cormorant-garamond {
  font-family: "Cormorant Garamond", serif;
}

.sinhala {
  font-family: "Noto Sans Sinhala", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.content-wrapper {
  padding: 0 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1320px !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menubar {
  font-weight: 500;
  width: 100%;
  border: none;
  background-color: #252525;
  justify-content: space-between;
  padding: 10px 0px;
}

.custom-button {
  color: #fff;
  font-weight: 500;
  line-height: 130%;
}

@media (max-width: 991px) {
  .custom-menubar .p-menubar {
    flex-wrap: wrap;
    max-width: 100%;
  }
}

.header-container {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}

.header {
  box-shadow: 0px -2px 56.4px -1px rgba(0, 0, 0, 0.13);
  background-color: #252525;
}

@media (max-width: 768px) {
  .menubar .p-menubar-root-list {
    display: none;
  }

  .text-10xl {
    font-size: 5rem;
  }
}

.gallery img {
  width: 100%;
  aspect-ratio: 3 / 4;
  border-radius: 2px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.gallery img:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.grid-item {
  text-decoration: none !important;
  position: relative;
}

h1 {
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  color: var(--secondary-color);
  font-size: 6rem;
  margin: 0;
  line-height: 6rem;
}

h2 {
  color: var(--secondary-color);
  font-size: 8rem;
  font-weight: 300;
  margin: 0;
  line-height: 8rem;
}

h3 {
  color: var(--primary-color);
  font-family: "Cormorant Garamond";
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0;
}

h4 {
  color: var(--secondary-color);
  font-family: "Cormorant Garamond";
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

h5 {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

h6 {
  color: var(--dark-500);
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

strong {
  font-weight: 600;
}

.page-context {
  color: #6a6a6a;
  text-align: center;
  max-width: 850px;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--secondary-color);
  background: #fff;
  box-shadow: 0px 60px 45.6px -21px rgba(0, 0, 0, 0.05);
  padding: 20px;
}

.btn-outlined {
  border-radius: 4px;
  color: #4f6461;
  font-size: 0.8rem;
  font-weight: 700;
  border: 1px solid #4f6461 !important;
}

.step {
  width: 70px;
  height: 70px;
  border-radius: 8px 0 100% 0px;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: absolute;
  margin-left: -21px;
  margin-top: -21px;
  font-family: "Cormorant Garamond", serif;
}

@media (max-width: 992px) {
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 4rem;
    line-height: 4rem;
  }

  .content-wrapper {
    max-width: 100% !important;
  }
}
