.item-image {
  width: 100%;
  border-radius: 8px;
  aspect-ratio: 3 / 4;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #4f6461;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: rgba(79, 100, 97, 0.5);
}

.dialog-button {
  width: 60%;
  margin-top: 20px;
  padding: 10px 0;
  background-color: #4f6461;
  color: #fff;
  border: 0;
  outline: none;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .dialog {
    width: 400px;
  }

  .dialog-button {
    width: 60%;
    margin-top: 20px;
  }
}
