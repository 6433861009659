.cart-item-image {
  max-width: 130px;
  height: fit-content;
  border-radius: 2px;
  margin-top: 1.5rem;
  margin-left: 1rem;
}

.cart-label {
  display: block;
  color: var(--primary);
  font-weight: 500;
}

.cart-button {
  float: right;
}

.chip {
  padding: 8px 16px;
  border-radius: 8px;
  border: 0.5px solid #b68d40;
  background: #fff9dd;
}

.summary-card {
  background: #fbfbfb;
}

.cart-total {
  border-radius: 8px;
  border: 2px dashed #4f6461;
  background: #f6fffe;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-weight: 600;
}

.cart-names {
  width: calc(100% / 2 - 50px);
}

.left-column {
  border-right: 1px solid var(--secondary-color);
}

.cart-label-1 {
  margin-top: -10px;
  margin-bottom: 10px;
}

.cart-label-2 {
  margin-top: 10px;
  margin-bottom: -10px;
}

.mobile-specs-delete-btn {
  display: none;
}

@media (max-width: 992px) {
  h3 {
    margin-top: 30px;
  }

  .left-column {
    border-right: none;
  }

  .desktop-delete-btn {
    display: none;
  }

  .summary-card {
    padding: 0px;
    margin-top: 20px;
    border: none;
    background-color: #fff;
    box-shadow: none;
  }

  .cart-names {
    width: calc(100% / 2 - 35px);
  }

  .item-specs {
    font-size: 0.85rem !important;
  }

  .chip {
    padding: 4px 4px;
    font-size: 0.75rem;
  }

}
