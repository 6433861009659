.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-container {
  background: #fff;
  padding: 10px;
  width: 1000px;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.arrow-btn-pop {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  z-index: 1;
}

.left-arrow-pop {
  left: 10px;
}

.right-arrow-pop {
  right: 10px;
}

.arrow-btn-pop:hover {
  background: rgba(0, 0, 0, 0.8);
}

.language-container {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  padding: 10px;
  font-size: 1.1rem;
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0 0 8px 8px;
}

.language-button.active {
  background-color: #4f6461;
  color: #4f6461;
}

.language-button:hover {
  background-color: #fff;
  color: #000;
}

@media (max-width: 768px) {
  .popup-container {
    width: 90%;
    height: 450px;
    padding: 15px;
    font-size: 0.9rem;
  }

  .arrow-btn-pop {
    font-size: 20px;
    padding: 8px;
  }

  .left-arrow-pop {
    left: 5px;
  }

  .right-arrow-pop {
    right: 5px;
  }

  .language-container {
    font-size: 0.9rem;
    padding: 8px;
  }

  .language-button {
    height: 30px;
    font-size: 0.8rem;
  }
}
