.order-tracking-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.tracking-header-div{
    margin-bottom: 50px;
}

.tracking-header {
    color: #b68d40;
    font-size: 1.5rem;
    font-family: "DM Sans";
    padding: 1rem;
    border-radius: 4px;
    text-align: center;
}

.order-no{
    color: #b68d40;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 2rem;
}

.tracking-timeline {
    position: relative;
    padding: 2rem 0;
    color: rgb(79, 100, 97);
}

.timeline-item {
    flex: 1;
    position: relative;
    z-index: 2;
}

.timeline-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.timeline-icon.active {
    background-color:#b68d40;
    color: white;
}

.timeline-line {
    flex: 1;
    height: 2px;
    background-color: #e0e0e0;
    position: relative;
    z-index: 1;
}

.timeline-line.active {
    background-color:#b68d40;
}

.timeline-icon i {
    font-size: 1.5rem;
}

.check-box .p-checkbox-box.p-highlight {
    background-color: #b68d40 !important; 
    border-color: #b68d40 !important; 
}

.order-info{
    color: rgb(79, 100, 97);
};

.main-div-2{
    margin-top: 7rem;
}

.tracking-sub-header{
    color: #b68d40;
    font-size: 1rem;
    font-family: "DM Sans";
    padding: 1rem;
    border-radius: 4px;
    text-align: center;
    margin-top: -30px;
}

.order-details-status{
    margin-top: 5rem;
}

.detail-header-2{
    margin-bottom: 2rem;
} 

@media (max-width: 768px) {
    .order-tracking-container {
        padding: 1rem;
    }

    .tracking-header {
        font-size: 1.2rem;
        margin-bottom: -2rem;
        margin-top: -1rem;
    }

    .tracking-sub-header {
        font-size: 0.9rem;
        margin-top: -10px;
        margin-bottom: -2rem;
    }

    .order-no {
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }

    .order-info {
        flex-direction: column;
        text-align: left; 
        padding: 1rem;
        gap: 0.5rem;
    }

    .detail-header-2 {
        text-align: center;
        margin-bottom: 1rem;
    }

    .lg\:col-9 {
        width: 100% !important;
        margin: 0;
    }

    .tracking-timeline {
        flex-direction: column;
        gap: 2rem;
    }

    .timeline-item {
        flex: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .timeline-line {
        display: none;
    }

    .timeline-icon {
        width: 40px;
        height: 40px;
    }

    .timeline-icon i {
        font-size: 1.2rem;
    }

    .tracking-header-div {
        margin-bottom: 20px;
    }

    .order-details-status {
        margin-top: 2rem;
    }

    .main-div-2 {
        margin-top: 4rem;
    }

    .view-all {
        font-size: 0.9rem;
    }
}