.contact-us-card {
  border: 1px solid #eaeaea;
  box-shadow: none;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.contact-us-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.contact-us-card-content {
  height: 265px;
}

.address {
  max-width: 200px;
}

.icon-border {
  border: 1px solid var(--text-color);
  padding: 8px;
  border-radius: 50%;
}

.text-touch {
  margin-left: 350px;
}

.contact-info-list {
  padding-left: 300px;
}

.contact-us-header {
  height: 700px;
}

@media (max-width: 768px) {
  .text-touch {
    margin-left: 150px;
  }

  .contact-info-list {
    padding-left: 0;
  }

  .icon-border {
    font-size: 0.8rem;
    border: none;
    padding: 0;
  }

  .contact-us-header {
    height: auto;
  }

  .contact-us-card-content {
    height: 180px;
  }
}
